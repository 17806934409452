import React from 'react';
import {Button, Card, CardContent, Modal, ModalClose, ModalDialog, Stack, Typography,} from "@mui/joy";
import Box from "@mui/joy/Box";

// components
import WebinarJitsy from "./WebinarJitsy";
import UserContext from "../../context/UserContext";
import BaseApi from "../../utils/BaseApi";

// icons
import CastIcon from '@mui/icons-material/Cast';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';



class WebinarCast extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }

    async setModalOpenTrue(){
        let status = await this.present();
        if (status === 201){
            this.setState({
                modalOpen: true,
            });
        }
    }

    async present(){
        const api = new BaseApi();
        const url = `webinar/${this.props.webinar.id}/present/`;
        let response = await api.get(
            url,
            {}
        );
        return response.status
    }


    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        if (this.props.webinar.onair && this.context.userObject && (this.props.webinar.speaker || this.props.webinar.moderator)){
            return (
                <>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        useFlexGap
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexWrap: 'wrap',
                            pb: 2
                        }}
                    >
                        <Button
                            onClick={this.setModalOpenTrue.bind(this)}
                            startDecorator={<CastIcon />}
                            color={'success'}
                            sx={{

                            }}
                        >
                            Выступать здесь
                        </Button>
                        <Button
                            onClick={()=> {
                                this.present.bind(this)();
                                window.open(
                                    `https://cast.multiphysics.ru/${this.props.webinar.uuid}#config.startWithAudioMuted=true&startAudioOnly=false&subject=${this.props.webinar.title}`,
                                    "_blank"
                                )
                            }}
                            startDecorator={<OpenInNewIcon />}
                            color={'success'}
                            variant="soft"
                            sx={{

                            }}
                        >
                            Выступать в отдельном окне
                        </Button>
                    </Stack>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                    maxWidth: 'calc(80vw)',
                                    maxHeight: 'calc(80vh)',
                                    pr: 2
                                }}
                            >
                                <Card
                                    color={'success'}
                                    sx={{
                                        mb: 2
                                    }}
                                >
                                    <CardContent>
                                        <Typography>
                                            Для запуска трансляции для посетителей: нажмите на (...) -> Начать трансляцию -> В форму вставить ссылку rtmp://ocast.multiphysics.ru:1935/live/XtfYN!drXWymWV237&9*EoWNgW3d5b -> Начать трансляцию
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <WebinarJitsy
                                    webinar={this.props.webinar}
                                    callBack={this.setModalOpenFalse.bind(this)}
                                />
                            </Box>
                        </ModalDialog>
                    </Modal>
                </>
            )
        }
        return null
    }
}

export default WebinarCast