import React from 'react';
import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedComponentList from "../Shared/CommonPaginatedComponentList";
import withRouter from "../../utils/withRouter";
import PostCard from "./PostCard";


class NewsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: null,
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrievePosts();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrievePosts();
        }
    }

    async retrievePosts(){
        this.setState({
            posts: null
        });
        const url = 'post/';
        const params = {
            category: 5,
            published: true,
            page: this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1,
        };
        const api = new BaseApi(
            'https://multiphysics.ru',
            '/api/'
        );
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                posts: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.posts){
            return(
                <CircularProgress/>
            )
        }
        return(
            <CommonPaginatedComponentList
                title={'Новости'}
                url={'post/news'}
                component={PostCard}
                componentKeyword={'post'}
                data={this.state.posts}
                pages={this.state.pages}
                pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
            />
        )
    }
}

export default withRouter(NewsList)