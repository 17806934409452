import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Grid, Stack, Typography} from "@mui/joy";

// components
import WebinarCard from "../Webinar/WebinarCard";
import PostCard from "../Post/PostCard";
import withNavigate from "../../utils/withNavigate";
import Button from "@mui/joy/Button";

// icons
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';


class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinars: null,
            news: null,
            projects: null,
            blog: null,
        };
    }

    async componentDidMount() {
        await this.retrieveWebinars();
        await this.retrievePosts();
    }

    async retrieveWebinars(){
        const url = 'webinar/';
        const params = {
            ended: false,
            ordering: 'start_dt',
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                webinars: response.data.results
            }
        );
    }

    async retrievePosts(){
        const url = 'post/';
        let response;
        let params;
        let news;
        let projects;
        let blog;
        params = {
            published: true,
            page_size: 6,
        };
        const api = new BaseApi(
            'https://multiphysics.ru',
            '/api/'
        );
        // news
        params.category = 5;
        response = await api.get(
            url,
            params
        );
        news = response.data.results
        // projects
        params.category = 6;
        response = await api.get(
            url,
            params
        );
        projects = response.data.results
        // blog
        params.category = 7;
        response = await api.get(
            url,
            params
        );
        blog = response.data.results;
        this.setState(
            {
                news: news,
                projects: projects,
                blog: blog,
            }
        );
    }

    render() {
        if (!this.state.webinars || !this.state.news || !this.state.projects || !this.state.blog){
            return(
                <CircularProgress/>
            )
        }
        return(
            <Stack
                direction={'column'}
                spacing={2}
                sx={{
                    width: '100%',
                }}
            >
                <Typography
                    level={'h2'}
                >
                    Вебинары
                </Typography>
                {this.state.webinars.length === 0?
                    <Stack
                        direction={'row'}
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <HourglassBottomIcon scale={2}/>
                        <Typography>
                            На данный момент не запланировано ни одного вебинара. Ожидайте обновления информации.
                        </Typography>
                    </Stack>:
                    null
                }
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.webinars.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                            >
                                <WebinarCard
                                    webinar={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/webinar/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Новости
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.news.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/news/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Выполненные проекты
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.projects.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/projects/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Блог
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.blog.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/blog/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
            </Stack>
        )
    }
}

export default withNavigate(HomePage);