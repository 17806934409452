import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import './index.css';

// components
import Root from './components/Root/Root';
import Error404 from './components/Errors/Error404';
import HomePage from "./components/HomePage/HomePage";
import WebinarFuture from "./components/Webinar/WebinarFuture";
import WebinarPast from "./components/Webinar/WebinarPast";
import WebinarDetail from "./components/Webinar/WebinarDetail";
import WebinarList from "./components/Webinar/WebinarList";
import PostDetail from "./components/Post/PostDetail";
import NewsList from "./components/Post/NewsList";
import ProjectsList from "./components/Post/ProjectsList";
import BlogList from "./components/Post/BlogList";
import WebinarMy from "./components/Webinar/WebinarMy";
import PostMy from "./components/Post/PostMy";


const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <Root />,
            errorElement: <Error404 />,
            children: [
                {
                    path: '',
                    element: <HomePage/>,
                },
                {
                    path: 'webinar/',
                    children:[
                        {
                            path: '',
                            element: <WebinarList/>
                        },
                        {
                            path: 'page/:pageNumber',
                            element: <WebinarList/>
                        },
                        {
                            path: 'future/',
                            element: <WebinarFuture/>,
                        },
                        {
                            path: 'future/page/:pageNumber',
                            element: <WebinarFuture/>,
                        },
                        {
                            path: 'past/',
                            element: <WebinarPast/>,
                        },
                        {
                            path: 'past/page/:pageNumber',
                            element: <WebinarPast/>,
                        },
                        {
                            path: 'my/',
                            element: <WebinarMy/>,
                        },
                        {
                            path: 'my/page/:pageNumber',
                            element: <WebinarMy/>,
                        },
                        {
                            path: 'detail/:webinarId',
                            element: <WebinarDetail/>,
                        },
                    ]
                },
                {
                    path: 'post/',
                    children:[
                        // {
                        //     path: '',
                        //     element: <PostList/>
                        // },
                        {
                            path: 'my/',
                            element: <PostMy />,
                        },
                        {
                            path: 'my/page/:pageNumber',
                            element: <PostMy />,
                        },
                        {
                            path: 'news/',
                            element: <NewsList/>,
                        },
                        {
                            path: 'news/page/:pageNumber',
                            element: <NewsList/>,
                        },
                        {
                            path: 'projects/',
                            element: <ProjectsList/>,
                        },
                        {
                            path: 'projects/page/:pageNumber',
                            element: <ProjectsList/>,
                        },
                        {
                            path: 'blog/',
                            element: <BlogList/>,
                        },
                        {
                            path: 'blog/page/:pageNumber',
                            element: <BlogList/>,
                        },
                        {
                            path: 'detail/:postId',
                            element: <PostDetail/>,
                        },

                    ]
                },
            ],
        },
    ],
);

export default router;
