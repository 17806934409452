import React from 'react';
import {Button, Chip,} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";

// icons
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';


class PostLike extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liked: false,
            // count: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveLikes()
    }

    async retrieveLikes() {
        const url = `like/likes`;
        const params = {
            post: this.props.post.id
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                // count: response.data.count,
                liked: response.data.liked,
            }
        );
    }

    async setLiked() {
        const url = `like/`;
        const data = {
            post_id: this.props.post.id
        };
        const api = new BaseApi();
        let response = await api.post(
            url,
            data,
        );
        this.setState(
            {
                count: this.state.count + 1,
                liked: true ,
            }
        );
    }

    async setNotLiked() {
        const url = `like/unlike`;
        const data = {
            post: this.props.post.id
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            data,
        );
        this.setState(
            {
                count: this.state.count - 1,
                liked: false,
            }
        );
    }

    render(){
        if (this.state.liked){
            return(
                <Button
                    startDecorator={<BookmarkIcon />}
                    color={'warning'}
                    variant={'solid'}
                    size={'sm'}
                    onClick={this.setNotLiked.bind(this)}
                >
                    Уже в избранном
                </Button>
            )
        }
        else {
            return(
                <Button
                    startDecorator={<BookmarkBorderIcon />}
                    color={'neutral'}
                    variant={'outlined'}
                    size={'sm'}
                    onClick={this.setLiked.bind(this)}
                >
                    Добавить в избранное
                </Button>
            )
        }

    }
}

export default PostLike;