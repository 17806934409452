import React from 'react';
import {
    Button,
    Card,
    CardContent,
    CardCover, Chip,
    CircularProgress,
    Stack,
    Typography
} from "@mui/joy";

// components
import withNavigate from "../../utils/withNavigate";
import BaseApi from "../../utils/BaseApi";
import withColorScheme from "../../utils/withColorScheme";
import Box from "@mui/joy/Box";
import PostLike from "./PostLike";


class PostCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: this.props.post?this.props.post:null,
        };
    }

    async componentDidMount() {
        if (!this.state.post) {
            await this.retrievePost();
        }
    }

    async retrievePost(){
        const url = `post/${this.props.postId}/`;
        const params = {

        };
        const api = new BaseApi(
            'https://multiphysics.ru',
            '/api/'
        );
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                post: response.data
            }
        );
    }

    render() {
        if (!this.state.post){
            return(
                <CircularProgress/>
            )
        }
        return(
            <Card>
                <CardCover>
                    <img
                        src={'https://multiphysics.ru/media/'+this.state.post.bg}
                        loading="lazy"
                        alt=""
                    />
                </CardCover>
                <CardCover
                    sx={{
                        background: (
                            this.props.colorScheme.colorScheme === 'dark'?
                                'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.8))':
                                'linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.8))'
                        )
                    }}
                />
                <CardContent
                    sx={{
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography
                        level="h3"
                        noWrap
                        onClick={() => (this.props.navigate(`/post/detail/${this.state.post.id}`))}
                        sx={{
                            cursor: 'pointer',
                            "&:hover": {
                                color: 'var(--joy-palette-primary-plainColor)',
                            }
                        }}
                    >
                        {this.state.post.title}
                    </Typography>
                    <Box
                        sx={{
                            minHeight: '50px',
                            maxHeight: '50px',
                            height: '50px',
                            overflow: 'hidden',
                            mb: 1
                        }}
                    >
                        <div dangerouslySetInnerHTML={{__html: this.state.post.short}} />
                    </Box>
                    <Box
                        sx={{
                            minHeight: '56px',
                            maxHeight: '56px',
                            height: '56px',
                            overflow: 'hidden',
                        }}
                    >
                        <Stack
                            spacing={1}
                            direction={'row'}
                            useFlexGap
                            sx={{ flexWrap: 'wrap' }}
                        >
                            {this.state.post.tag.map((each) =>(
                                <Chip>{each.title}</Chip>
                            ))}
                        </Stack>
                    </Box>
                    <Stack
                        spacing={2}
                        direction={'row'}
                        sx={{
                            alignItems: 'center',
                            pt: 1
                        }}
                    >
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    color="default"*/}
                        {/*    size="sm"*/}
                        {/*    onClick={*/}
                        {/*        () => (this.props.navigate(`/post/detail/${this.state.post.id}`))*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    Подробнее...*/}
                        {/*</Button>*/}
                        <PostLike
                            post={this.state.post}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default withNavigate(withColorScheme(PostCard))
