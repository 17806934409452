import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ColorSchemeToggle from "../Shared/ColorSchemeToggle";
import {Stack} from "@mui/joy";
import UserModal from "../User/UserModal";


export function openSidebar() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
}

export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}

export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
}

export default function Header() {
    return (
        <Sheet
            sx={{
                // display: {xs: 'flex', md: 'none'},
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: '66px',
                // height: 'var(--Header-height)',
                zIndex: 1004,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'sm',
            }}
        >
            {/*<GlobalStyles*/}
            {/*    styles={(theme) => ({*/}
            {/*        ':root': {*/}
            {/*            '--Header-height': '52px',*/}
            {/*            [theme.breakpoints.up('md')]: {*/}
            {/*                '--Header-height': '0px',*/}
            {/*            },*/}
            {/*        },*/}
            {/*    })}*/}
            {/*/>*/}
            <IconButton
                onClick={() => toggleSidebar()}
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <MenuIcon/>
            </IconButton>
            <Stack
                direction={'row'}
            >
                <UserModal />
            </Stack>

        </Sheet>
    );
}