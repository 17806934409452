import React from 'react';
import {Button, CircularProgress, Grid, Modal, ModalClose, ModalDialog, Stack,} from "@mui/joy";
import Box from "@mui/joy/Box";

// components
import UserContext from "../../context/UserContext";
import BaseApi from "../../utils/BaseApi";

// icons
import CastIcon from '@mui/icons-material/Cast';
import Chat from "../Chat/Chat";


class WebinarOCast extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            room: null
        }
    }

    async retrieveRoom(){
        const url = `room/`;
        const data = {
            related_app: 'Webinar',
            related_app_id: this.props.webinar.id
        };
        const api = new BaseApi();
        let response = await api.post(
            url,
            data,
        );
        this.setState(
            {
                room: response.data.id
            }
        );
    }

    async setModalOpenTrue() {
        let status = await this.present();
        if (status === 201) {
            this.setState({
                modalOpen: true,
            });
        }
        await this.retrieveRoom()
    }

    async present() {
        const api = new BaseApi();
        const url = `webinar/${this.props.webinar.id}/present/`;
        let response = await api.get(
            url,
            {}
        );
        return response.status
    }


    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        if (this.props.webinar.onair && this.context.userObject && this.props.webinar.attended){
            return (
                <>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        useFlexGap
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexWrap: 'wrap',
                            pb: 2
                        }}
                    >
                        <Button
                            onClick={this.setModalOpenTrue.bind(this)}
                            startDecorator={<CastIcon />}
                            color={'danger'}
                            sx={{

                            }}
                        >
                            Присоединиться здесь
                        </Button>
                    </Stack>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    // overflowY: 'scroll',
                                    maxWidth: 'calc(80vw)',
                                    width: 'calc(80vw)',
                                    maxHeight: 'calc(80vh)',
                                    height: 'calc(80vh)',
                                }}
                            >
                                <Grid
                                    sx={{
                                        height: '100%'
                                    }}
                                    container={true}
                                >
                                    <Grid
                                        xs={12}
                                        md={8}
                                        sx={{
                                            height: '100%'
                                        }}
                                    >
                                        <iframe
                                            src="https://ocast.multiphysics.ru/embed/video"
                                            title="Вебинар"
                                            height="100%" width="100%"
                                            referrerPolicy="origin"
                                            allowFullScreen>
                                        </iframe>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        md={4}
                                        sx={{
                                            height: '100%'
                                        }}
                                    >
                                        {
                                            this.state.room?
                                                <Chat
                                                    room={this.state.room}
                                                />:
                                                null
                                        }

                                    </Grid>
                                </Grid>
                            </Box>
                        </ModalDialog>
                    </Modal>
                </>
            )
        }
        return null
    }
}


export default WebinarOCast