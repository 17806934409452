import * as React from 'react';
import {Outlet, useLocation, useSearchParams} from 'react-router-dom';
import { UAParser } from 'ua-parser-js';

// mui
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import {unstable_createCssVarsTheme as createCssVarsTheme} from "@mui/system";

// redux
import { Provider } from 'react-redux'
import store from "../../context/redux";

// my app
import mergedTheme from "../../theme.ts";
import ScrollToTop from "./ScrollToTop";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import {UserProvider} from "../../context/UserContext";
import GlobalStyles from "@mui/joy/GlobalStyles";


import '../../App.css';
import {useEffect} from "react";
import BaseApi from "../../utils/BaseApi";


export default function Root(){
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const url = 'stat/'
    const api = new BaseApi();

    for (const element of searchParams){
        if (element[0].startsWith('utm')){
            localStorage.setItem(
                element[0],
                element[1]
            );
        }
    }

    let lastStat = false;

    let parser = new UAParser(window.navigator.userAgent);
        let parserResults = parser.getResult();
        let mouseMoves = [];
        let start = new Date();

    window.addEventListener("beforeunload", (event) =>
    {
        let now = new Date();
        localStorage.setItem(
            'visit-last-dt',
            now.getTime()
        );
        let duration;
        if (mouseMoves[mouseMoves.length - 1] !== undefined){
            duration = mouseMoves[mouseMoves.length - 1].time;
        }
        else {
            return false;
        }
        const data = {
            visit: localStorage.getItem('visit-id'),
            url: location.pathname,
            search_params: Object.fromEntries(searchParams.entries()),
            ua:  parserResults.ua,
            browser: parserResults.browser,
            engine: parserResults.engine,
            os: parserResults.os,
            device: parserResults.device,
            cpu: parserResults.cpu,
            window_height: window.innerHeight,
            window_width: window.innerWidth,
            mouse_moves: mouseMoves,
            duration: duration,
            referrer: document.referrer,
        };
        api.post(
            url,
            data
        ).then(

        );
        return false;
    });

    useEffect(() => {
        lastStat = true;
        function onMouseUpdate(e) {
            let end = new Date();
            mouseMoves.push(
                {
                    x: e.pageX,
                    y: e.pageY,
                    type: 'move',
                    time: (end.getTime() - start.getTime()),
                }
            )
        }

        function onMouseDown(e) {
            let end = new Date();
            mouseMoves.push(
                {
                    x: e.pageX,
                    y: e.pageY,
                    type: 'down',
                    time: (end.getTime() - start.getTime()),
                }
            )
        }

        document.addEventListener('mousemove', onMouseUpdate, false);
        document.addEventListener('mousedown', onMouseDown, false);
        return () => {
            const data = {
                visit: localStorage.getItem('visit-id'),
                url: location.pathname,
                search_params: Object.fromEntries(searchParams.entries()),
                ua:  parserResults.ua,
                browser: parserResults.browser,
                engine: parserResults.engine,
                os: parserResults.os,
                device: parserResults.device,
                cpu: parserResults.cpu,
                window_height: window.innerHeight,
                window_width: window.innerWidth,
                mouse_moves: mouseMoves,
                duration: mouseMoves[mouseMoves.length - 1].time,
                referrer: document.referrer,
            };
            let now = new Date();
            if (!searchParams.hasOwnProperty('noStat')) {
                api.post(
                    url,
                    data
                ).then(
                    localStorage.setItem(
                        'visit-last-dt',
                        now.getTime()
                    )
                );
            }
            document.removeEventListener('mousemove', onMouseUpdate, false);
            document.removeEventListener('mousedown', onMouseDown, false);
        }
    })



    return (
        <UserProvider>
            <Provider store={store}>
                <ScrollToTop />
                <CssVarsProvider
                    theme={createCssVarsTheme(mergedTheme)}
                >
                    <GlobalStyles
                        styles={(theme) => ({
                            'img': {
                                maxWidth: '100%',
                                height: 'auto',
                            }
                        })}
                    />
                    <CssBaseline />
                    <Box
                        id={'main'}
                        className={'RootContentBox'}
                        sx={
                        {
                            display: 'flex',
                            minHeight: 'calc(100vh)',
                            maxHeight: 'calc(100vh)',
                            overflow: 'auto'
                        }
                    }>

                        <Header />
                        <Sidebar />
                        <Box
                            sx={{
                                p: 2,
                                width: 'calc(100vw - var(--Sidebar-width))',
                                pt: '80px',
                                [mergedTheme.breakpoints.down('md')]: {
                                    'width': '100%',
                                },
                            }}
                        >
                            <Outlet />
                        </Box>
                    </Box>
                </CssVarsProvider>
            </Provider>
        </UserProvider>
    )
}
