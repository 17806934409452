import {useColorScheme} from "@mui/joy/styles";

const withColorScheme = WrappedComponent => props => {
    let colorScheme = useColorScheme();

    return (
        <WrappedComponent
            {...props}
            colorScheme={colorScheme}
        />
    )
}

export default withColorScheme;
