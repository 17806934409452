import React from 'react';
import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedComponentList from "../Shared/CommonPaginatedComponentList";
import withRouter from "../../utils/withRouter";
import PostCard from "./PostCard";


class PostMy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: null,
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrievePosts();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrievePosts();
        }
    }

    async retrievePosts(){
        this.setState({
            posts: null
        });
        const url = 'like/';
        const params = {
            page: this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1,
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                posts: response.data.results.map((each)=>(each.post_id)),
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.posts){
            return(
                <CircularProgress/>
            )
        }
        return(
            <CommonPaginatedComponentList
                title={'Проекты'}
                url={'post/projects'}
                component={PostCard}
                componentKeyword={'postId'}
                data={this.state.posts}
                pages={this.state.pages}
                pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
            />
        )
    }
}

export default withRouter(PostMy);