import React from "react";

import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from "@mui/joy/Sheet";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";


// components
import withNavigate from "../../utils/withNavigate";
import ColorSchemeToggle from "../Shared/ColorSchemeToggle";
import { closeSidebar } from "../Header/Header";
import UserContext from "../../context/UserContext";
import withColorScheme from "../../utils/withColorScheme";

//icons
import UpdateIcon from '@mui/icons-material/Update';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HomeIcon from '@mui/icons-material/Home';
import CastForEducationSharpIcon from '@mui/icons-material/CastForEducationSharp';
import GradeIcon from '@mui/icons-material/Grade';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BookIcon from '@mui/icons-material/Book';
import FeedIcon from '@mui/icons-material/Feed';
import BookmarkIcon from '@mui/icons-material/Bookmark';



class Sidebar extends React.Component{
    static contextType = UserContext

    render(){
        return(
            <>
                <Sheet
                    className="Sidebar"
                    sx={{
                        position: { xs: 'fixed', md: 'sticky' },
                        transform: {
                            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                            md: 'none',
                        },
                        transition: 'transform 0.4s, width 0.4s',
                        zIndex: 1005,
                        height: 'calc(100vh)',
                        width: 'var(--Sidebar-width)',
                        top: 0,
                        p: 2,
                        flexShrink: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRight: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <GlobalStyles
                        styles={(theme) => ({
                        ':root': {
                            '--Sidebar-width': '220px',
                            [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                            },
                        },
                        })}
                    />
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={'https://multiphysics.ru/static/logo_web_h34px.svg'}
                                style={{
                                    filter: (
                                        this.props.colorScheme.colorScheme === 'dark'?
                                            'brightness(0%) contrast(100%) invert(100%)':
                                            ''
                                    ),
                                }}
                                alt={'logo'}
                            />
                        </Stack>
                        <ColorSchemeToggle />
                    </Stack>
                    {/*<Typography>*/}
                    {/*    Инженерный хаб*/}
                    {/*</Typography>*/}
                    <List>
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    closeSidebar();
                                    this.props.navigate('/')
                                }}
                            >
                                <ListItemDecorator>
                                    <HomeIcon
                                        fontSize="lg"
                                    />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Главная
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <CastForEducationSharpIcon
                                    fontSize="lg"
                                />
                            </ListItemDecorator>
                            <ListItemContent>
                                Вебинары
                            </ListItemContent>
                        </ListItem>
                        {this.context.userObject?
                            <ListItem>
                                <ListItemButton
                                    onClick={
                                        () => {
                                            closeSidebar();
                                            this.props.navigate('/webinar/my/')
                                        }
                                    }
                                    sx={{
                                        ml:2
                                    }}
                                >
                                    <ListItemDecorator>
                                        <GradeIcon
                                            fontSize="lg"
                                            color={'warning'}
                                        />
                                    </ListItemDecorator>
                                    <ListItemContent>
                                        Мои
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>:
                            null
                        }
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    closeSidebar();
                                    this.props.navigate('/webinar/future/')
                                }}
                                sx={{
                                    ml:2
                                }}
                            >
                                <ListItemDecorator>
                                    <UpdateIcon
                                        fontSize="lg"
                                    />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Будущие
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    closeSidebar();
                                    this.props.navigate('/webinar/past/')}
                                }
                                sx={{
                                    ml:2
                                }}
                            >
                                <ListItemDecorator>
                                    <LocalAirportIcon
                                        fontSize="lg"
                                    />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Прошедшие
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        {/*<ListItem>*/}
                        {/*    <ListItemButton*/}
                        {/*        onClick={() => (this.props.navigate('/course'))}*/}
                        {/*    >*/}
                        {/*        <ListItemDecorator>*/}
                        {/*            <CastForEducationSharpIcon*/}
                        {/*                fontSize="lg"*/}
                        {/*            />*/}
                        {/*        </ListItemDecorator>*/}
                        {/*        <ListItemContent>*/}
                        {/*            Курсы*/}
                        {/*        </ListItemContent>*/}
                        {/*    </ListItemButton>*/}
                        {/*</ListItem>*/}
                        <ListItem>
                            <ListItemDecorator>
                                <FeedIcon
                                    fontSize="lg"
                                />
                            </ListItemDecorator>
                            <ListItemContent>
                                Публикации
                            </ListItemContent>
                        </ListItem>
                        {this.context.userObject?
                            <ListItem>
                                <ListItemButton
                                    onClick={() => {
                                        closeSidebar();
                                        this.props.navigate('/post/my/')}
                                    }
                                    sx={{
                                        ml:2
                                    }}
                                >
                                    <ListItemDecorator>
                                        <BookmarkIcon
                                            fontSize="lg"
                                            color={'warning'}
                                        />
                                    </ListItemDecorator>
                                    <ListItemContent>
                                        Избранное
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>:
                            null
                        }
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    closeSidebar();
                                    this.props.navigate('/post/news')}
                                }
                                sx={{
                                    ml:2
                                }}
                            >
                                <ListItemDecorator>
                                    <NewspaperIcon
                                        fontSize="lg"
                                    />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Новости
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    closeSidebar();
                                    this.props.navigate('/post/projects')}
                                }
                                sx={{
                                    ml:2
                                }}
                            >
                                <ListItemDecorator>
                                    <PrecisionManufacturingIcon
                                        fontSize="lg"
                                    />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Проекты
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    closeSidebar();
                                    this.props.navigate('/post/blog')}
                                }
                                sx={{
                                    ml:2
                                }}
                            >
                                <ListItemDecorator>
                                    <BookIcon
                                        fontSize="lg"
                                    />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Блог
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Sheet>
                <Box
                    className="SidebarOverlay"
                    sx={{
                        position: 'fixed',
                        zIndex: 998,
                        top: 0,
                        left: 0,
                        width: 'calc(100vw)',
                        height: 'calc(100vh)',
                        opacity: 'var(--SideNavigation-slideIn)',
                        // backgroundColor: 'var(--joy-palette-background-backdrop)',
                        transition: 'opacity 0.4s',
                        transform: {
                            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                            // lg: 'translateX(-100%)',
                        },
                    }}
                    onClick={() => closeSidebar()}
                />
            </>
        )
    }
    
}

export default withNavigate(withColorScheme(Sidebar));