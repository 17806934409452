import {deepmerge} from "@mui/utils";
import type {} from "@mui/material/themeCssVarsAugmentation";
import {
    extendTheme as extendMuiTheme,
    PaletteColor,
    TypeText,
    TypeAction,
    Overlays,
    PaletteColorChannel,
    PaletteAlert,
    PaletteAppBar,
    PaletteAvatar,
    PaletteChip,
    PaletteFilledInput,
    PaletteLinearProgress,
    PaletteSlider,
    PaletteSkeleton,
    PaletteSnackbarContent,
    PaletteSpeedDialAction,
    PaletteStepConnector,
    PaletteStepContent,
    PaletteSwitch,
    PaletteTableCell,
    PaletteTooltip,
    Shadows,
    ZIndex,
    createTheme
} from "@mui/material/styles";

import colors from "@mui/joy/colors";
import {green, orange, purple, teal, lime, indigo} from '@mui/material/colors';
import {
    extendTheme as extendJoyTheme,
    FontSize,
    Theme as JoyTheme,
    ThemeCssVar as JoyThemeCssVar,
    shouldSkipGeneratingVar as joyShouldSkipGeneratingVar
} from "@mui/joy/styles";

import {CommonColors} from "@mui/material/styles/createPalette";
import {TypeBackground} from "@mui/material";
import {ButtonPropsColorOverrides} from "@mui/material/Button";
import {ChipPropsColorOverrides} from '@mui/material/Chip';
import { PaletteRange } from "@mui/joy/styles";

// extends Joy theme to include tokens from Material UI
declare module "@mui/joy/styles" {
    interface Palette {
        secondary: PaletteColorChannel;
        error: PaletteColorChannel;
        danger: PaletteColorChannel;
        dividerChannel: string;
        action: TypeAction;
        Alert: PaletteAlert;
        AppBar: PaletteAppBar;
        Avatar: PaletteAvatar;
        Chip: PaletteChip;
        FilledInput: PaletteFilledInput;
        LinearProgress: PaletteLinearProgress;
        Skeleton: PaletteSkeleton;
        Slider: PaletteSlider;
        SnackbarContent: PaletteSnackbarContent;
        SpeedDialAction: PaletteSpeedDialAction;
        StepConnector: PaletteStepConnector;
        StepContent: PaletteStepContent;
        Switch: PaletteSwitch;
        TableCell: PaletteTableCell;
        Tooltip: PaletteTooltip;
        lime: PaletteRange;
        purple: PaletteRange;
        teal: PaletteRange;
        orange: PaletteRange;
        indigo: PaletteRange;

    }

    interface ColorPalettePropOverrides {
        lime: true;
        purple: true;
        teal: true;
        orange: true;
        danger: true;
        indigo: true;
    }

    interface PalettePrimary extends PaletteColor {
    }

    interface PaletteInfo extends PaletteColor {
    }

    interface PaletteSuccess extends PaletteColor {
    }

    interface PaletteWarning extends PaletteColor {
    }

    interface PaletteCommon extends CommonColors {
    }

    interface PaletteText extends TypeText {
    }

    interface PaletteBackground extends TypeBackground {
    }

    interface ThemeVars {
        // attach to Joy UI `theme.vars`
        shadows: Shadows;
        overlays: Overlays;
        zIndex: ZIndex;
    }
}

type MergedThemeCssVar = { [k in JoyThemeCssVar]: true };

declare module "@mui/material/styles" {
    interface Theme {
        vars: JoyTheme["vars"];
    }
    interface Palette {
        lime: Palette['primary'],
        purple: Palette['primary'],
        teal: Palette['primary'],
        orange: Palette['primary'],
        indigo: Palette['primary'],
        'gray-dark': Palette['primary'],
        gray: Palette['primary'],
        danger?: PaletteOptions['primary'],
    }
    interface PaletteOptions {
        lime?: PaletteOptions['primary'],
        purple?: PaletteOptions['primary'],
        teal?: PaletteOptions['primary'],
        orange?: PaletteOptions['primary'],
        indigo: Palette['primary'],
        'gray-dark'?: PaletteOptions['primary'],
        gray?: PaletteOptions['primary'],
        danger?: PaletteOptions['primary'],
    }
    interface ThemeCssVarOverrides extends MergedThemeCssVar {
    }
}


declare module "@mui/material/SvgIcon" {
    interface SvgIconPropsSizeOverrides extends Record<keyof FontSize, true> {
    }

    interface SvgIconPropsColorOverrides {
        danger: true;
        neutral: true;
    }
}


declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        lime: true;
        purple: true;
        teal: true;
        orange: true;
        'gray-dark': true;
        danger: true;
        indigo: true;
    }
}


declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        lime: true;
        purple: true;
        teal: true;
        orange: true;
        'gray-dark': true;
        danger: true;
        indigo: true;
    }
}

const rawTheme = createTheme();

const {unstable_sxConfig: muiSxConfig, ...muiTheme} = extendMuiTheme({
    cssVarPrefix: "joy",
    colorSchemes: {
        light: {
            palette: {
                lime: rawTheme.palette.augmentColor({
                    color: {
                        main: green['A700'],
                    },
                        name: 'lime',
                    }),
                purple: rawTheme.palette.augmentColor({
                    color: {
                        main: purple[500],
                    },
                        name: 'purple',
                    }),
                teal: rawTheme.palette.augmentColor({
                    color: {
                        main: teal[500],
                    },
                        name: 'teal',
                    }),
                orange: rawTheme.palette.augmentColor({
                    color: {
                        main: orange[500],
                    },
                        name: 'orange',
                    }),
                'gray-dark': rawTheme.palette.augmentColor({
                    color: {
                        main: colors.grey[900],
                    },
                        name: 'gray-dark',
                    }),
                gray: rawTheme.palette.augmentColor({
                    color: {
                        main: colors.grey[500],
                    },
                        name: 'orange',
                    }),
                indigo: rawTheme.palette.augmentColor({
                    color: {
                        main: colors.blue[700],
                    },
                        name: 'indigo',
                    }),
                primary: {
                    main: colors.blue[500]
                },
                grey: colors.grey,
                error: {
                    main: colors.red[500]
                },
                danger: {
                    main: colors.red[500]
                },
                info: {
                    main: purple[500]
                },
                success: {
                    main: colors.green[500]
                },
                warning: {
                    main: colors.yellow[400]
                },
                common: {
                    white: "#FFF",
                    black: "#09090D"
                },
                divider: colors.grey[200],
                text: {
                    primary: colors.grey[800],
                    secondary: colors.grey[600]
                }
            }
        },
        dark: {
            palette: {
                lime: rawTheme.palette.augmentColor({
                    color: {
                        main: green['A700'],
                    },
                        name: 'lime',
                    }),
                purple: rawTheme.palette.augmentColor({
                    color: {
                        main: purple[500],
                    },
                        name: 'purple',
                    }),
                teal: rawTheme.palette.augmentColor({
                    color: {
                        main: teal[500],
                    },
                        name: 'teal',
                    }),
                orange: rawTheme.palette.augmentColor({
                    color: {
                        main: orange[500],
                    },
                        name: 'orange',
                    }),
                'gray-dark': rawTheme.palette.augmentColor({
                    color: {
                        main: colors.grey[900],
                    },
                        name: 'gray-dark',
                    }),
                gray: rawTheme.palette.augmentColor({
                    color: {
                        main: colors.grey[500],
                    },
                        name: 'gray',
                    }),
                indigo: rawTheme.palette.augmentColor({
                    color: {
                        main: colors.blue[700],
                    },
                        name: 'indigo',
                    }),
                primary: {
                    main: colors.blue[600]
                },
                grey: colors.grey,
                error: {
                    main: colors.red[600]
                },
                danger: {
                    main: colors.red[500]
                },
                info: {
                    main: purple[600]
                },
                success: {
                    main: colors.green[600]
                },
                warning: {
                    main: colors.yellow[400]
                },
                common: {
                    white: "#FFF",
                    black: "#09090D"
                },
                divider: colors.grey[800],
                text: {
                    primary: colors.grey[100],
                    secondary: colors.grey[300]
                }
            }
        }
    }
});

function createColorConfig(name){
    return(
        {
            solidBg: `var(--joy-palette-${name}-400)`,
            solidActiveBg: `var(--joy-palette-${name}-500)`,
            outlinedBorder: `var(--joy-palette-${name}-700)`,
            outlinedColor: `var(--joy-palette-${name}-600)`,
            outlinedActiveBg: `var(--joy-palette-${name}-900)`,
            softColor: `var(--joy-palette-${name}-500)`,
            softBg: `var(--joy-palette-${name}-900)`,
            softActiveBg: `var(--joy-palette-${name}-800)`,
            plainColor: `var(--joy-palette-${name}-500)`,
            plainActiveBg: `var(--joy-palette-${name}-900)`
        }
    )
}

const {unstable_sxConfig: joySxConfig, ...joyTheme} = extendJoyTheme({
    shouldSkipGeneratingVar: (keys) => joyShouldSkipGeneratingVar(keys),
    colorSchemes: {
        light: {
            palette: {
                lime: Object.assign(lime, createColorConfig('lime')),
                purple: Object.assign(purple, createColorConfig('purple')),
                teal: Object.assign(teal, createColorConfig('teal')),
                orange: Object.assign(orange, createColorConfig('orange')),
                indigo: Object.assign(indigo, createColorConfig('indigo')),
            }
        },
        dark: {
            palette: {
                lime: Object.assign(lime, createColorConfig('lime')),
                purple: Object.assign(purple, createColorConfig('purple')),
                teal: Object.assign(teal, createColorConfig('teal')),
                orange: Object.assign(orange, createColorConfig('orange')),
                indigo: Object.assign(indigo, createColorConfig('indigo')),
            }
        }
    }
});

const mergedTheme = deepmerge(muiTheme, joyTheme);

// @ts-ignore
mergedTheme.unstable_sxConfig = {
    ...muiSxConfig,
    ...joySxConfig
};

export default mergedTheme;
