import React from 'react';
import {Modal, ModalDialog, ModalClose, Stack, Link} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import LaunchIcon from '@mui/icons-material/Launch';


class Rules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async setModalOpenFalse(){
        await this.setState({
            modalOpen: false,
        })
    }

    render() {
        return(
            <>
                <Stack>
                    <Link
                        onClick={this.setModalOpenTrue.bind(this)}
                        // startDecorator={<LoginIcon />}
                        color={'default'}
                        size={'sm'}
                        sx={{
                            p: 0
                        }}
                    >
                        Пользовательскому соглашению <LaunchIcon sx={{ml: 1}}/>
                    </Link>
                </Stack>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                            sx={{
                                // ml: '130px',
                            }}
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                }}
                            >
                                <h1>Пользовательское соглашение</h1>
                                <p>Настоящий документ «<strong>Пользовательское соглашение</strong>» представляет собой предложение Акционерного общества «Центр инженерно-физических расчетов и анализа», заключить договор на изложенных ниже условиях Соглашения.</p>
                                <p>&nbsp;</p>
                                <h2>1.Общие положения Пользовательского соглашения</h2>
                                <p>1.1. В настоящем документе и вытекающих или связанным с ним отношениях Сторон применяются следующие термины и определения:</p>
                                <p><strong>А) Сайт</strong>&nbsp;– сайт, расположенный в сети Интернет по адресу:&nbsp;<a href="https://hub.multiphysics.ru/" target="_blank" rel="noopener">https://hub.multiphysics.ru</a></p>
                                <p><strong>Б) Администрация сайта</strong>&nbsp; - Акционерное общество «Центр инженерно-физических расчетов и анализа», расположенное по адресу 195197, г. Санкт-Петербург, Кондратьевский проспект, дом 15, корпус 2, литер З, помещение 328, ИНН: 7720622417, КПП 780401001, ОГРН 1087746773553.</p>
                                <p><strong>В) Пользователь</strong>&nbsp; - физическое лицо пользователь Сайта, использующее его в установленном порядке.</p>
                                <p><strong>Г) Соглашение</strong>&nbsp;- настоящее соглашение со всеми дополнениями и изменениями.</p>
                                <p>1.2. Использование вами Сайта любым способом и в любой форме в пределах его объявленных функциональных возможностей, включая:</p>
                                <ul>
                                <li>просмотр размещенных на Сайте материалов;</li>
                                <li>регистрация и/или авторизация на Сайте, заполнение веб-форм,</li>
                                <li>размещение или отображение на Сайте любых материалов, включая, но не ограничиваясь, такими как: тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/или иная информация,</li>
                                </ul>
                                <p>создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса Российской Федерации. Пользователь, приступивший к использованию Сайта, считается подтвердившим свое согласие с условиями настоящего Соглашения в порядке, предусмотренном ч. 3 ст. 434 Гражданского кодекса Российской Федерации.</p>
                                <p>&nbsp;</p>
                                <h2>2. Общие условия пользования Сайтом</h2>
                                <p>2.2. Пользователь может использовать материалы Сайта и предоставляемые на Сайте системы исключительно в некоммерческих целях, то есть для личного использования и ознакомления.</p>
                                <p>2.3. Администрация сайта оставляет за собой право вносить в Соглашение изменения, которые вступают в силу с момента публикации. Текст действующей редакции Соглашения всегда доступен по адресу&nbsp;<a href="https://hub.multiphysics.ru/" target="_blank" rel="noopener">https://hub.multiphysics.ru</a></p>
                                <p>2.4. Дальнейшее использование пользователем Сайта после внесения подобных изменений означает согласие пользователя с соответствующими изменениями. В случае несогласия Пользователя с настоящим Соглашением или его обновлениями (изменениями), Пользователь обязан отказаться от использования Сайта.</p>
                                <p>2.5. К Соглашению и отношениям между пользователем и Администрацией сайта, возникающим в связи с использованием настоящего Сайта, применяются нормы действующего законодательства Российской Федерации. Все права на Сайт в целом, включая права использование сетевого адреса (доменного имени) multiphysics.ru принадлежат Администрации сайта. Администрация предоставляет доступ к Сайту всем заинтересованным лицам в соответствии с настоящим Соглашением и действующим законодательством Российской Федерации. Сайт может содержать ссылки на другие сайты, которые не находятся в собственности Администрации и не связаны с Администрацией. Администрация сайта не контролирует и не берет на себя никакой ответственности за содержание таких сайтов.</p>
                                <p>2.6. Администрация сайта сохраняет за собой право в любое время изменять оформление Сайта, его содержание, изменять или дополнять используемые тексты, программное обеспечение и другие объекты, используемые или хранящиеся на Сайте, любые материалы в любое время с предварительным уведомлением или без такового.</p>
                                <p>2.7. Пользователь понимает и соглашается с тем, что Администрация может разместить на Сайте рекламу. Используя Сайт, пользователь признает право Администрации размещать рекламу без предварительного уведомления. Администрация не несет ответственности за взаимоотношения пользователя и рекламодателей, разместивших рекламу на Сайте, в том числе, в отношении самой рекламы, а также товаров и услуг таких рекламодателей - третьих лиц.</p>
                                <p>&nbsp;</p>
                                <h2>
                                    <strong>3. Заполнение веб-форм на Сайте</strong>
                                </h2>
                                <p>3.1. Заполнение веб-форм пользователем на Сайте является добровольным и бесплатным.</p>
                                <p>3.2. При заполнении веб-форм на Сайте пользователь обязан предоставить достоверную и актуальную информацию, включая уникальное для каждого пользователя сочетание логина и пароля доступа к Сайту, а также свои подлинные фамилию и имя.</p>
                                <p>3.3. Для завершения процесса заполнения веб-форм пользователь обязан нажать кнопку «Настоящим подтверждаю, что ознакомлен с условиями Пользовательского соглашения и принимаю их».</p>
                                <p>3.4. Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству Российской Федерации, предоставленной при заполнении веб-форм информации и ее чистоту от прав третьих лиц.</p>
                                <p>3.5. Принимая условия настоящего Соглашения путем заполнения веб-формы на Сайте, пользователь, являющийся физическим лицом, подтверждает свое согласие на обработку Администрацией, его персональных данных, предоставленных при регистрации на Сайте (фамилию, имя, контактные данные адреса электронной почты, а также иные данные, относящиеся к личности пользователя и ставшие доступными Администрации в связи с регистрацией пользователя на Сайте). Обработка персональных данных пользователя осуществляется в соответствии с действующим законодательством Российской Федерации путем смешанной обработки персональных данных пользователя (автоматизированным и неавтоматизированным способами), с передачей таких данных по внутренней сети Администрации и сети Интернет.</p>
                                <p>Обработка персональных данных пользователя включает совершение любого действия (операции) или совокупности действий (операций) с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение, передачу (распространение, предоставление, доступ) персональных данных, включая обработку вышеуказанными способами.</p>
                                <p>3.6. Администрация Сайта принимает все необходимые меры для защиты персональных данных пользователя от неправомерного доступа, изменения, раскрытия или уничтожения. Администрация предоставляет доступ к персональным данным пользователя только тем работникам, которым эта информация необходима для обеспечения функционирования Сайта и реализации договора.</p>
                                <p>Администрация Сайта вправе использовать предоставленную пользователем информацию, в том числе персональные данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий пользователей).</p>
                                <p>Раскрытие предоставленной пользователем информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях. Согласие предоставляется с момента успешной регистрации на Сайте, и действует в течение пяти лет.</p>
                                <p>По истечении срока действия согласия на обработку персональных данных оно автоматически пролонгируется на тот же срок. Количество пролонгаций не ограничено.</p>
                                <p>Согласие может быть отозвано пользователем в любой момент путем передачи Администрации подписанного пользователем письменного уведомления.</p>
                                <p>Пользователь вправе отозвать свое согласие на обработку своих персональных данных, направив подписанное письменное уведомление об отзыве по адресу: 195197, г. Санкт-Петербург, Кондратьевский проспект, дом 15, корпус 2, литер З, помещение 328, либо отправив сканированную копию подписанного уведомления электронное по адресу электронной почты: <a href="mailto:solve@multiphysics.ru" target="_blank" rel="noopener">solve@multiphysics.ru</a>, указав в уведомлении те персональные данные, которые такое лицо сообщил при регистрации на сайте, и согласие на обработку которых отзывается.</p>
                                <p>В случае получения уведомления об отзыве согласия Администрация прекращают обработку таких персональных данных и уничтожают персональные данные в разумные сроки.</p>
                                <p>3.7. Пользователь не имеет права передавать свои логин и пароль третьим лицам, несет полную ответственность за их сохранность, самостоятельно выбирая способ их хранения.</p>
                                <p>3.8. Если пользователем не доказано обратное, любые действия, совершенные с использованием его логина и пароля, считаются совершенными соответствующим пользователем. В случае несанкционированного доступа к логину и паролю и/или персональной странице пользователя, или распространения логина и пароля пользователь обязан незамедлительно сообщить об этом по адресу: 195197, г. Санкт-Петербург, Кондратьевский проспект, дом 15, корпус 2, литер З, помещение 328.</p>
                                <p>&nbsp;</p>
                                <h2><strong>4. Заключительные положения</strong></h2>
                                <p>4.1. Пользователь и Администрация Сайта будут пытаться решить все возникшие между ними споры и разногласия путем переговоров. В случае невозможности разрешить споры и разногласия путем переговоров они подлежат рассмотрению в соответствующем суде по месту нахождения Администрации Сайта.</p>
                            </Box>
                        </ModalDialog>
                    </Modal>
                </>
        )
    }
}

export default Rules
