import React from 'react';
import {
    Card,
    CardContent,
    CardCover,
    Chip,
    CircularProgress,
    Stack,
    Typography
} from "@mui/joy";
import Box from "@mui/joy/Box";

// components
import WebinarAttendeeCreate from "./WebinarAttendeeCreate";
import withNavigate from "../../utils/withNavigate";
import Base64Image from "../Shared/Base64Image";
import BaseApi from "../../utils/BaseApi";
import withColorScheme from "../../utils/withColorScheme";

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WebinarRecording from "./WebinarRecording";


class WebinarCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinar: this.props.webinar?this.props.webinar:null,
        };
    }

    async componentDidMount() {
        if (!this.state.webinar) {
            await this.retrieveWebinar();
        }
    }

    async retrieveWebinar(){
        const url = `webinar/${this.props.webinarId}/`;
        const params = {

        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                webinars: response.data.results
            }
        );
    }

    render() {
        if (!this.state.webinar){
            return(
                <CircularProgress/>
            )
        }
        return(
            <Card>
                <Base64Image
                    model={'webinar'}
                    field={'background_image'}
                    id={this.state.webinar.id}
                />
                <CardCover
                    sx={{
                        background: (
                            this.props.colorScheme.colorScheme === 'dark'?
                                'linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.8))':
                                'linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.8))'
                        )
                    }}
                />
                <CardContent
                    sx={{
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography
                        level="h3"
                        // noWrap
                        onClick={() => (this.props.navigate(`/webinar/detail/${this.state.webinar.id}`))}
                        sx={{
                            cursor: 'pointer',
                            "&:hover": {
                                color: 'var(--joy-palette-primary-plainColor)',
                            }
                        }}
                    >
                        {this.state.webinar.title}
                    </Typography>
                    <Box
                        sx={{
                        }}
                    >
                        <div dangerouslySetInnerHTML={{__html: this.state.webinar.short_description}} />
                    </Box>
                    <Box
                        sx={{
                        }}
                    >
                        <Stack
                            spacing={1}
                            direction={'row'}
                            useFlexGap
                            sx={{ flexWrap: 'wrap' }}
                        >
                            {this.state.webinar.tags.map((each) =>(
                                <Chip>{each.title}</Chip>
                            ))}
                        </Stack>
                    </Box>
                    <Stack
                        spacing={2}
                        direction={'row'}
                        sx={{
                            pt: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}
                        useFlexGap
                    >
                        <Chip
                            variant="soft"
                            startDecorator={<CalendarMonthIcon />}
                            sx={{
                                height: '0px'
                            }}
                        >
                            {this.state.webinar.start_dt}
                        </Chip>
                        <Chip
                            variant="soft"
                            startDecorator={<AccessTimeIcon />}
                            color={'primary'}
                            sx={{
                                height: '0px'
                            }}
                        >
                            Продолжительность {this.state.webinar.duration} минут
                        </Chip>
                    </Stack>
                    <Stack
                        spacing={2}
                        direction={'row'}
                        sx={{
                            pt: 1,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                        useFlexGap
                    >
                        {/*<Button*/}
                        {/*    variant="soft"*/}
                        {/*    color="primary"*/}
                        {/*    size="sm"*/}
                        {/*    onClick={() => (this.props.navigate(`/webinar/detail/${this.state.webinar.id}`))}*/}
                        {/*>*/}
                        {/*    Подробнее*/}
                        {/*</Button>*/}
                        <WebinarAttendeeCreate
                            webinar={this.state.webinar}
                        />
                        <WebinarRecording
                            webinar={this.state.webinar}
                            chip={true}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default withNavigate(withColorScheme(WebinarCard))
