import React from 'react';
import {Modal, ModalDialog, ModalClose, Stack, Link} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import LaunchIcon from '@mui/icons-material/Launch';


class PersonalData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async setModalOpenFalse(){
        await this.setState({
            modalOpen: false,
        })
    }

    render() {
        return(
            <>
                <Stack>
                    <Link
                        onClick={this.setModalOpenTrue.bind(this)}
                        // startDecorator={<LoginIcon />}
                        color={'default'}
                        size={'sm'}
                        sx={{
                            p: 0
                        }}
                    >
                        Соглашению об обработке персональных данных <LaunchIcon sx={{ml: 1}} />
                    </Link>
                </Stack>
                    <Modal
                        open={this.state.modalOpen}
                    >
                        <ModalDialog
                            layout={'center'}
                            size="lg"
                            sx={{
                                // ml: '130px',
                            }}
                        >
                            <ModalClose
                                onClick={this.setModalOpenFalse.bind(this)}
                            />
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                }}
                            >
                                <p>Пользователь, заполняя и отправляя любые веб-формы на веб-сайтах Акционерного общества «Центр инженерно-физических расчетов и анализа» принимает настоящее Соглашение на обработку персональных данных (далее – Соглашение). Принятием (акцептом) оферты Соглашения является отправка данных через веб-форму на сайте <a href="https://hub.multiphysics.ru/" target="_blank" rel="noopener">https://hub.multiphysics.ru</a></p>
                                <p>Настоящим я подтверждаю, что в соответствии с законодательством Российской Федерации, в том числе Федеральным законом от 27.07.2006г. № 152-ФЗ «О персональных данных», даю согласие на обработку сотрудниками  Акционерное общество «Центр инженерно-физических расчетов и анализа», расположенное по адресу 195197, г. Санкт-Петербург, Кондратьевский проспект, дом 15, корпус 2, литер З, помещение 328, ИНН: 7720622417, КПП 780401001, ОГРН 1087746773553, следующих своих персональных данных:</p>
                                <ul>
                                    <li>фамилия, имя и отчество;</li>
                                    <li>должность;</li>
                                    <li>название компании;</li>
                                    <li>ИНН компании;</li>
                                    <li>номер контактного телефона;</li>
                                    <li>адрес электронной почты;</li>
                                    <li>любая иная информация, относящаяся к моей личности, доступная либо известная в любой конкретный момент времени Оператору.</li>
                                </ul>
                                <p>Предоставляю право осуществлять все действия (операции) с моими персональными данными, включая сбор, систематизацию, накопление, хранение, обновление, изменение, использование, распространение (в том числе передача), обезличивание, блокирование, уничтожение персональных данных, а также осуществление любых иных действий с моими персональными данными с учетом действующего законодательства.</p>
                                <p>Оператор вправе обрабатывать мои персональные данные посредством внесения их в электронную базу данных, включения в списки (реестры) и отчетные формы, предусмотренные документами, регламентирующими предоставление отчетных данных (документов), и передавать их уполномоченным органам.</p>
                                <p>Настоящим я признаю и подтверждаю, что в случае необходимости предоставления персональных данных для достижения указанных выше целей третьим лицам, а равно как при привлечении третьих лиц к оказанию услуг в моих интересах, передачи Оператором принадлежащих ему функций и полномочий иному лицу, Оператор вправе в необходимом объеме раскрывать для совершения вышеуказанных действий информацию обо мне лично (включая мои персональные данные) таким третьим лицам, их агентам и иным уполномоченным ими лицам, а также предоставлять таким лицам соответствующие документы, содержащие такую информацию. Также настоящим признаю и подтверждаю, что настоящее согласие считается данным мною любым третьим лицам, указанным выше, с учетом соответствующих изменений, и любые такие третьи лица имеют право на обработку персональных данных на основании настоящего согласия при условии соблюдения требований законодательства (Федеральный закон № 152-ФЗ от 27.07.2006 года).</p>
                                <p>Настоящее согласие действует бессрочно.</p>
                                <p>Я подтверждаю, что мне известно о праве отозвать свое согласие посредством составления соответствующего письменного документа, который может быть направлен мной в адрес Оператора по почте заказным письмом с уведомлением о вручении либо вручен лично под расписку представителю Оператора.</p>
                            </Box>
                        </ModalDialog>
                    </Modal>
                </>
        )
    }
}

export default PersonalData
