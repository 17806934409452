import React from 'react';

import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import WebinarCard from "./WebinarCard";
import CommonPaginatedComponentList from "../Shared/CommonPaginatedComponentList";
import withRouter from "../../utils/withRouter";


class WebinarList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinars: null,
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveWebinars();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveWebinars();
        }
    }

    async retrieveWebinars(){
        this.setState({
            webinars: null
        });
        const url = 'webinar/';
        const params = {
            page: this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1,
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                webinars: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.webinars){
            return(
                <CircularProgress/>
            )
        }
        return(
            <CommonPaginatedComponentList
                title={'Вебинары'}
                url={'webinar'}
                component={WebinarCard}
                componentKeyword={'webinar'}
                data={this.state.webinars}
                pages={this.state.pages}
                pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
            />
        )
    }
}

export default withRouter(WebinarList);