import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import TelegramIcon from "@mui/icons-material/Telegram";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

// components
import BaseApi from "../../utils/BaseApi";
import {Button, Stack, Textarea} from "@mui/joy";


class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            text: '',
        };
    }

    async componentDidMount() {
        await this.retrieveMessages();
        let authToken = localStorage.getItem("authToken")?localStorage.getItem("authToken") : null;
        if (!authToken){
        }
        this.client = new W3CWebSocket(
            `${process.env.REACT_APP_WS_HOST}/ws/room/${this.props.room}/?token=${authToken}`
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
        }
        this.client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer) {
                this.setState((state) => ({
                    messages: [
                        {
                            content: dataFromServer.content,
                            owner: dataFromServer.owner,
                            created_on: dataFromServer.created_on,
                        },
                        ...state.messages,
                    ],
                }));
            }
        };
        this.chat_timer = setInterval(() => (this.client.send(JSON.stringify({type: "ping"}))), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.chat_timer);
    }

    async retrieveMessages(){
        const url = 'message/';
        const api = new BaseApi();
        let params = {
            room: this.props.room,
        };
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                messages: response.data.results,
            }
        );
    }

    setText(text) {
        this.setState(
            {
                text: text,
            }
        );
    }

    addEmoji(event){
        this.setText(`${this.state.text}${event.target.textContent}`);
        document.getElementsByClassName('MuiTextarea-textarea')[0].focus()
    }

    async postMessage(){
        let data = {
            type: "message",
            content: this.state.text,
        };
        await this.client.send(
            JSON.stringify(data)
        );
        await this.setState(
            {
                text: ''
            }
        );
    }

    render() {
        return(
            <Sheet
                variant="plain"
                color="neutral"
                sx={
                    {
                        alignContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 'md',
                        width: '100%',
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: 'stretch',
                        p: 0,
                        pl: 1,
                    }
                }
            >

                <Box sx={
                    {
                        display: 'flex',
                        width: '100%',
                        overflowY: 'scroll',
                        flexDirection: 'column-reverse',
                    }
                }>
                        {this.state.messages.map(each => {
                            return(
                                <Card
                                    sx={{
                                        mt: 1,
                                        width: '100%',
                                    }}
                                >
                                    <CardContent>
                                        <Typography level={'body-md'}>
                                            <strong>
                                                {each.owner.first_name} {each.owner.last_name} написал {each.created_on}:
                                            </strong>
                                        </Typography>
                                        <Typography level={'body-md'}>
                                            {each.content}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        })}
                </Box>
                <Box
                    sx={
                        {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            pt: 2
                        }
                    }
                >
                    <Textarea
                        id={'chat_textarea'}
                        onChange={
                            (event) => this.setText.bind(this)(event.target.value)
                        }
                        onKeyDown={(event) => {
                            if (event.ctrlKey && event.keyCode === 13) {
                                event.preventDefault();
                                this.postMessage.bind(this)();
                            }
                            }
                        }
                        value={this.state.text}
                        minRows={3}
                        maxRows={7}
                        startDecorator={
                            <Box sx={{display: 'flex', gap: 0.5, flex: 1}}>
                                <IconButton variant="outlined" color="neutral" onClick={this.addEmoji.bind(this)}>
                                    👍
                                </IconButton>
                                <IconButton variant="outlined" color="neutral" onClick={this.addEmoji.bind(this)}>
                                    😀
                                </IconButton>
                                <IconButton variant="outlined" color="neutral" onClick={this.addEmoji.bind(this)}>
                                    👏
                                </IconButton>
                                <IconButton variant="outlined" color="neutral" onClick={this.addEmoji.bind(this)}>
                                    😕
                                </IconButton>
                            </Box>
                        }
                        endDecorator={
                            <Stack
                                direction={'row'}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    size={'sm'}
                                    color="primary"
                                    onClick={this.postMessage.bind(this)}
                                    endDecorator={<TelegramIcon />}
                                >
                                    Отправить
                                </Button>
                                <Typography
                                    level={'body-xs'}
                                    sx={{
                                        ml: 1
                                    }}
                                >
                                    CTRL+Enter
                                </Typography>
                            </Stack>

                        }
                    />
                </Box>
            </Sheet>
        )
    }
}

export default Chat