import axios from "axios";
import * as qs from 'qs'


class BaseApi {
    constructor(host=null, url=null, headers=null, arrayFormat='indices') {
        this.baseHost = host?host:process.env.REACT_APP_API_HOST;
        let apiUrl = url?url:'/api/'
        this.baseUrl = `${this.baseHost}${apiUrl}`;
        this.headers = headers;
        this.arrayFormat = arrayFormat;
    }

    async get_token() {
        let authToken = localStorage.getItem("authToken")?localStorage.getItem("authToken") : null;
        if (authToken) {
            return `Token ${authToken}`
        }
    }

    async get(url, params) {
        let headers;
        if (this.headers){
            headers = this.headers
        }
        else {
            const token = await this.get_token();
            headers = {
                Authorization: token
            }
        }
        try {
            const response = await axios.get(
                `${this.baseUrl}${url}`,
                {
                    headers: headers,
                    params: params,
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: this.arrayFormat })
                    }
                }
              )
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401){
                    localStorage.removeItem('authToken');
                }
                if (error.response.status < 500) {
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }

    async post(url, data, multipart=false) {
        let headers;
        if (this.headers){
            headers = this.headers
        }
        else {
            const token = await this.get_token();
            headers = {
                Authorization: token
            }
        }
        if (multipart) {
            headers['Content-Type'] =  'multipart/form-data'
        }
        try {
            const response = await axios.post(
                `${this.baseUrl}${url}`,
                data,
                {
                    headers: headers
                }
            );
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401){
                    localStorage.removeItem('authToken');
                    window.location.reload();
                }
                if (error.response.status < 500) {
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }

    async patch(url, data, multipart=false) {
        let headers;
        if (this.headers){
            headers = this.headers
        }
        else {
            const token = await this.get_token();
            headers = {
                Authorization: token
            }
        }
        if (multipart) {
            headers['Content-Type'] =  'multipart/form-data'
        }
        try {
            const response = await axios.patch(
                `${this.baseUrl}${url}`,
                data,
                {
                    headers: headers
                }
            );
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status < 500) {
                    if (error.response.status === 401){
                        localStorage.removeItem('authToken');
                        window.location.reload();
                    }
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }

    async delete(url) {
        let headers;
        if (this.headers){
            headers = this.headers
        }
        else {
            const token = await this.get_token();
            headers = {
                Authorization: token
            }
        }
        try {
            const response = await axios.delete(
                `${this.baseUrl}${url}`,
                {
                    headers: headers
                },

            );
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response.status < 500) {
                if (error.response.status === 401){
                    localStorage.removeItem('authToken');
                    window.location.reload();
                }
                return {
                    'status': error.response.status,
                    'data': error.response.data,
                }
            }
            else {
                console.error(error);
            }
        }
    }
}

export default BaseApi