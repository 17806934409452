import React from 'react';

// components
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";
import {
    AspectRatio,
    Card,
    CardContent,
    CardOverflow,
    Chip,
    CircularProgress,
    Grid,
    Stack,
    Typography
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

// components
import WebinarAttendeeCreate from "./WebinarAttendeeCreate";
import Base64Image from "../Shared/Base64Image";
import PostCard from "../Post/PostCard";
import withNavigate from "../../utils/withNavigate";
import WebinarCast from "./WebinarCast";
import WebinarRecording from "./WebinarRecording";
import WebinarOnairButton from "./WebinarOnairButton";
import WebinarOnairNotice from "./WebinarOnairNotice";
import WebinarOCast from "./WebinarOCast";

// icons
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";


class WebinarDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webinar: null,
            news: null,
            projects: null,
            blog: null,
        };
    }

    async componentDidMount() {
        await this.retrieveWebinar();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveWebinar();
        }
    }

    async retrieveWebinar(){
        this.setState(
            {
                webinar: null
            }
        );
        const url = `webinar/${this.props.params.webinarId}/`;
        const params = {

        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                webinar: response.data
            }
        );
        await this.retrievePosts(response.data);
    }

    async retrievePosts(webinar){
        const url = 'post/';
        let response;
        let params;
        let news;
        let projects;
        let blog;
        params = {
            published: true,
            page_size: 4,
        };
        const api = new BaseApi(
            'https://multiphysics.ru',
            '/api/',
            null,
            'repeat',
        );
        // news
        params.category = 5;
        params.tag = webinar.tags.map((each) => (each.web_id));
        response = await api.get(
            url,
            params
        );
        news = response.data.results
        // projects
        params.category = 6;
        response = await api.get(
            url,
            params
        );
        projects = response.data.results
        // blog
        params.category = 7;
        response = await api.get(
            url,
            params
        );
        blog = response.data.results;
        this.setState(
            {
                news: news,
                projects: projects,
                blog: blog,
            }
        );
    }

    render() {
        if (!this.state.webinar || !this.state.news || !this.state.projects || !this.state.blog){
            return(
                <CircularProgress/>
            )
        }
        return(
            <Stack
                spacing={2}
            >
                <Card>
                    <CardOverflow>
                        <AspectRatio
                            ratio="5/1"
                            sx={{ minWidth: 200 }}
                        >
                            <Base64Image
                                model={'webinar'}
                                field={'image'}
                                id={this.state.webinar.id}
                            />
                        </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                        <Typography
                            level="h3"
                            sx={{
                                pb: 2,
                                pt: 2,
                            }}
                        >
                            {this.state.webinar.title}
                        </Typography>
                        <WebinarOnairButton
                            webinar={this.state.webinar}
                            callBack={this.retrieveWebinar.bind(this)}
                        />


                        <WebinarCast
                            webinar={this.state.webinar}
                        />
                        <Stack
                            spacing={2}
                            direction={'row'}
                            sx={{
                            }}
                        >
                            <WebinarOnairNotice
                                webinar={this.state.webinar}
                            />
                            <WebinarOCast
                                webinar={this.state.webinar}
                            />
                            <WebinarRecording
                                webinar={this.state.webinar}
                            />
                        </Stack>
                        {this.state.webinar.tags.length?
                            <Box
                                sx={{
                                    pt: 2,
                                    pb: 2,
                                }}
                            >
                                <Stack
                                    spacing={1}
                                    direction={'row'}
                                    useFlexGap
                                    sx={{ flexWrap: 'wrap' }}
                                >
                                    {this.state.webinar.tags.map((each) =>(
                                        <Chip>{each.title}</Chip>
                                    ))}
                                </Stack>
                            </Box>:
                            null
                        }

                        <Stack
                            spacing={2}
                            direction={'row'}
                            sx={{
                                pt: 2,
                                pb: 2
                            }}
                        >
                            <WebinarAttendeeCreate
                                webinar={this.state.webinar}
                            />
                        </Stack>
                        <div dangerouslySetInnerHTML={{__html: this.state.webinar.short_description}} />
                        <div dangerouslySetInnerHTML={{__html: this.state.webinar.description}} />
                        <Typography>
                            {
                                this.state.webinar.speakers.length > 1?
                                    'Докладчики: ':
                                    'Докладчик: '
                            }
                            {this.state.webinar.speakers.map((each)=>{
                                    return(
                                        <span style={{marginRight: '4px'}}>
                                            {each.last_name} {each.first_name}, {each.role};
                                        </span>
                                    )
                                })
                            }
                        </Typography>
                        <Stack
                            spacing={2}
                            direction={'row'}
                            sx={{
                                pt: 2,
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                            useFlexGap
                        >
                            <Chip
                                variant="soft"
                                startDecorator={<CalendarMonthIcon />}
                                sx={{
                                    height: '0px'
                                }}
                            >
                                {this.state.webinar.start_dt}
                            </Chip>
                            <Chip
                                variant="soft"
                                startDecorator={<AccessTimeIcon />}
                                color={'primary'}
                                sx={{
                                    height: '0px'
                                }}
                            >
                                Продолжительность {this.state.webinar.duration} минут
                            </Chip>
                        </Stack>
                        <Stack
                            spacing={2}
                            direction={'row'}
                            sx={{
                                pt: 2,
                                pb: 2
                            }}
                        >
                            <WebinarAttendeeCreate
                                webinar={this.state.webinar}
                            />
                        </Stack>
                    </CardContent>
                </Card>
                <Typography
                    level={'h2'}
                >
                    Связанные новости
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.news.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/news/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Связанные проекты
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.projects.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/projects/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
                <Typography
                    level={'h2'}
                >
                    Связанные публикации в блоге
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    {
                        this.state.blog.map((each)=>(
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <PostCard
                                    post={each}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Button
                    onClick={() => {this.props.navigate('/post/blog/')}}
                    endDecorator={<KeyboardArrowRight />}
                    color={'default'}
                >
                    Все
                </Button>
            </Stack>
        )
    }
}

export default withNavigate(withRouter(WebinarDetail));
