import React from 'react';
import {Button,} from "@mui/joy";

// components
import UserContext from "../../context/UserContext";

// icons
import CastIcon from '@mui/icons-material/Cast';
import BaseApi from "../../utils/BaseApi";


class WebinarOnairButton extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            onair: this.props.webinar.onair,
        }
    }

    async onair(){
        const api = new BaseApi();
        const url = `webinar/${this.props.webinar.id}/onair/`;
        let response = await api.get(
            url,
            {}
        );
        if(response.status===200){
            await this.props.callBack();
        }
    }


    render() {
        if (!this.props.webinar.onair && this.context.userObject && (this.props.webinar.speaker || this.props.webinar.moderator)){
            return (
                <Button
                    onClick={this.onair.bind(this)}
                    startDecorator={<CastIcon />}
                    color={'danger'}
                >
                    Запустить прямой эфир!
                </Button>
            )
        }
        return null
    }
}

export default WebinarOnairButton