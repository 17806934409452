import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CardCover} from "@mui/joy";


class Base64Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };
    }
    async componentDidMount() {
        await this.retrieveImage();
    }

    async retrieveImage(){
        const url = 'image/';
        const params = {
            model: this.props.model,
            field: this.props.field,
            id: this.props.id,
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                image: response.data
            }
        );
    }

    render() {
        if (!this.state.image){
            return(
                <></>
            )
        }
        return(
            <CardCover>
                <img
                    src={`data:image/jpeg;base64,${this.state.image}`}
                    loading="lazy"
                    alt=""
                />
            </CardCover>

        )
    }
}

export default Base64Image;