import React, { useRef, useState } from 'react';
import Box from "@mui/joy/Box";
import {CircularProgress} from "@mui/joy";
import {JitsiMeeting} from "@jitsi/react-sdk";


const WebinarJitsy = (props) => {
    const apiRef = useRef();
    const [ logItems, updateLog ] = useState([]);
    const [ knockingParticipants, updateKnockingParticipants ] = useState([]);

    const printEventOutput = payload => {
        updateLog(items => [ ...items, JSON.stringify(payload) ]);
    };

    const handleAudioStatusChange = (payload, feature) => {
        if (payload.muted) {
            updateLog(items => [ ...items, `${feature} off` ])
        } else {
            updateLog(items => [ ...items, `${feature} on` ])
        }
    };

    const handleChatUpdates = payload => {
        if (payload.isOpen || !payload.unreadCount) {
            return;
        }
        apiRef.current.executeCommand('toggleChat');
        updateLog(items => [ ...items, `you have ${payload.unreadCount} unread messages` ])
    };

    const handleKnockingParticipant = payload => {
        updateLog(items => [ ...items, JSON.stringify(payload) ]);
        updateKnockingParticipants(participants => [ ...participants, payload?.participant ])
    };

    const resolveKnockingParticipants = condition => {
        knockingParticipants.forEach(participant => {
            apiRef.current.executeCommand('answerKnockingParticipant', participant?.id, condition(participant));
            updateKnockingParticipants(participants => participants.filter(item => item.id === participant.id));
        });
    };

    const handleJitsiIFrameRef = iframeRef => {
        iframeRef.style.border = '10px solid #3d3d3d';
        iframeRef.style.background = '#3d3d3d';
        iframeRef.style.height = '100%';
        iframeRef.style.marginBottom = '20px';
    };


    const handleApiReady = apiObj => {
        apiRef.current = apiObj;
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
        apiRef.current.on('audioMuteStatusChanged', payload => handleAudioStatusChange(payload, 'audio'));
        apiRef.current.on('videoMuteStatusChanged', payload => handleAudioStatusChange(payload, 'video'));
        apiRef.current.on('raiseHandUpdated', printEventOutput);
        apiRef.current.on('titleViewChanged', printEventOutput);
        apiRef.current.on('chatUpdated', handleChatUpdates);
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
    };

    const handleReadyToClose = () => {
        /* eslint-disable-next-line no-alert */
        props.callBack();
    };

    const renderLog = () => logItems.map(
        (item, index) => (
            <div
                style = {{
                    fontFamily: 'monospace',
                    padding: '5px'
                }}
                key = { index }>
                {item}
            </div>
        )
    );

    const renderSpinner = () => (
        <div style = {{
            fontFamily: 'sans-serif',
            textAlign: 'center'
        }}>
            <CircularProgress />
        </div>
    );

    return (
        <Box
            sx={{
                height: '600px',
            }}
        >
            <Box
                sx={{
                    height: '95%'
                }}
            >
                <JitsiMeeting
                    domain = { 'cast.multiphysics.ru' }
                    roomName = { props.webinar.uuid }
                    spinner = { renderSpinner }
                    configOverwrite = {{
                        startWithAudioMuted: true,
                        startAudioOnly: false,
                        subject: props.webinar.title,
                    }}
                    lang = 'ru'
                    onApiReady = { externalApi => handleApiReady(externalApi) }
                    onReadyToClose = { handleReadyToClose }
                    getIFrameRef = { handleJitsiIFrameRef }
                />
            {/*{renderLog()}*/}
            </Box>
        </Box>
    );
};

export default WebinarJitsy;